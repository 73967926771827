import './App.css';
import logo from "./assets/logo-removebg-preview.png"
import evilLogo from "./assets/evil-logo-removebg-preview.png"

function App() {
  return (
    <div className="App bg-blue-300">
      <div className="Home h-screen  bg-blue-300 flex items-center justify-center ">
        <div className="navbar fixed h-20 w-full backdrop-blur-lg top-0 border-b-lime-500 border-b-8 flex items-center justify-between drop-shadow-[2px_4px_rgba(240,0,0,1)] z-20 md:px-20 px-4">
          <h1 className='text-lime-500 text-2xl font-superman drop-shadow-[2px_2px_rgba(240,0,0,1)]'>$BOPE</h1>
          <a href="https://raydium.io/swap/?outputCurrency=6URSuk1bZyDU3XGUgyLPb6RLB5TvEjEjzDmfcoGWzFfT" target='_blank' className='bg-lime-500 md:text-2xl text-xl font-superman border-4 border-red-600 md:py-1 md:px-4 py-1 px-2 text-red-600'>BUY $BOPE</a>
        </div>
        <div className="container mt-[100px] flex items-center justify-center md:flex-row flex-col px-8">
          <div className="flex flex-col items-start justify-start gap-8">
            <h1 className='text-lime-500 md:text-6xl text-4xl font-superman drop-shadow-[2px_4px_rgba(240,0,0,1)]'>“I am the Bull, the Moon, and the FOMO. No one comes to the 100x except through me.”</h1>
            <h1 className='md:text-xl text-sm px-2 text-lime-500 font-gluten text-center my-2 drop-shadow-[1px_2px_rgba(240,0,0,1)]'>6URSuk1bZyDU3XGUgyLPb6RLB5TvEjEjzDmfcoGWzFfT</h1>
            <div className="social flex flex-row items-center justify-center gap-4">
              <a href="https://t.me/bookofpepelations" target='_blank' className='bg-lime-500 text-red-500  rounded-full'>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="rounded-full bg-lime-500 p-1 bi bi-telegram" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09" />
                </svg>
              </a>
              <a href="https://twitter.com/bopesol" target='_blank' className='bg-lime-500 text-red-500  rounded-full flex items-center justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="rounded-full bg-lime-500 p-2 bi bi-twitter-x" viewBox="0 0 16 16">
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                </svg>
              </a>
              <a href="https://dexscreener.com/solana/facmxhotsigff6jpu3m4ejnc6gtqyghub1zxylipfafr" target='_blank' className='bg-lime-500 text-red-500  rounded-full flex'>
                <svg data-v-deabe132="" xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className=" bg-lime-500 p-2 rounded-full" fill-rule="evenodd" viewBox="0 0 252 300" >
                  <path data-v-deabe132="" d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
                  <path data-v-deabe132="" d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
                </svg>
              </a>
            </div>
          </div>
          <img src={logo} className='md:w-1/2' alt="" />
        </div>
      </div>
      <div className="About min-h-screen  bg-blue-300 flex items-center justify-center ">
        <div className="container mt-[100px] flex items-center justify-center md:flex-row-reverse flex-col px-8">
          <div className="flex flex-col md:items-end items-center md:justify-start justify-center gap-8">
            <h1 className='text-lime-500 md:text-6xl md:text-end text-center text-4xl font-superman drop-shadow-[2px_4px_rgba(240,0,0,1)]'>“He could not find it in his mind that he would serve BOPE, the gem, in obedience…He planned how he would create a stronger fomo for himself”</h1>


          </div>
          <img src={evilLogo} className='md:w-1/2' alt="" />
        </div>
      </div>
    </div>
  );
}

export default App;
